import React from 'react';
import Fraction from '../../../fraction2'
import Note from '../../../note';
import Mag from '../../../magnitude'

export default function Step4(){
  let text="Now, since the triangle ADB > section GDB, and since the triangle BDC <  section BDH, the triangle ADB, compared with the section GDB, has a greater ratio than the triangle BDC, when compared with the section BDH."

  return <div className="step" id="Step4">
    <p>Next, we can take that information and put it into Aristarchus' inequality statement, where <Mag>Angle CAD</Mag> is analogous to our sight-lines on the Moon, and <Mag>Angle DAF</Mag> is analogous to the equilateral triangle. </p>

    <p>It's a long proof, so it's included in the appendix. At a glance one can see that the diagram has a sector, which is a clue that it is in fact derived from the distance inequality.</p>

    <p>For now, take for granted that<br/></p>
    <p>
        <Fraction>
          <Mag>Angle CAD</Mag>
          <Mag>Angle DAF</Mag>
        </Fraction>
        <span> &lt; </span>
        <Fraction>
          <Mag>Line CD</Mag>
          <Mag>Line DF</Mag>
        </Fraction>
      </p>
  </div>
}