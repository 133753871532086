import React, { Component } from 'react'
import { NavHashLink } from 'react-router-hash-link'
import Mag from '../../magnitude'

function getTransitionEndEventName() {
  var transitions = {
      "transition"      : "animationend",
      "OTransition"     : "oTransitionEnd",
      "MozTransition"   : "transitionend",
      "WebkitTransition": "webkitTransitionEnd"
   }
  let bodyStyle = document.body.style;
  for(let transition in transitions) {
      if(bodyStyle[transition] != undefined) {
          return transitions[transition];
      } 
  }
}

export default class HomePage extends Component {

  componentDidMount(){
    window.initHomepageVectors();
    window.customSvgHomepage();
    let input = document.querySelector('.dayRotate');
    let sun = document.querySelector('#sun')
    sun.classList.add("sun-rotate");
    var sticky = new window.Sticky('.sticky');
  
    let transitionEndEventName = getTransitionEndEventName();
    var sunRotationCounter = 0;
    var multiplyer = 10;
    sun.addEventListener(transitionEndEventName, () => {
      sunRotationCounter+=5;  
      sun.classList.remove("sun-rotate")
      //sunWrapper.style.transform = 'rotate('+ sunRotationCounter +'deg)';  
      setTimeout(function(){
        sun.classList.add("sun-rotate");
      }, 500);
    });
    input.classList.add("rotate");
  }
  
  

  render() {
    return <div id="homepage"><div className="jumbotron">
    <div className="container">
    <div className="of-h row">
      <div className="col-sm">
        <h1 className="display-3">The Geometre's Grimoire</h1>
        <p>How geometry became trigonometry</p>
        
        <p><a className="btn btn-primary btn-lg" target="_blank" href="https://www.amazon.com/dp/B08F719JW3" role="button">Buy the book</a></p>
        <p>
          <NavHashLink to="/#intro">Find out more about it</NavHashLink>
        </p>
      </div>
      <div className="col-sm pt-2">
        <div className="wrapper">
          <div className="dayRotate">
            <img className="" src="images/twoCircles/outer-wheel.gif" alt="" /> 
          </div>
          <img className="p-a " id="sun" src="images/twoCircles/Sun.png" alt="" />
          <img id="innerWheel" className="p-a" src="images/twoCircles/inner-wheel.png" alt="" />
        </div>
        <p className="small">Illustration of the Ptolemaic model displayed as a 24-hour clock, as the Sun moves with the stars in the East to West direction each day, it also moves slowly in the opposite direction.</p>
      </div>
      </div>
    </div>
  </div>
  
  <div class="pt-5" id="intro">
    <div className="container pt-4" >
      <div className="align-items-center row" data-sticky-container>
        <div className="col-md-6 d-flex justify-content-center" >
          <div className="sticky" data-margin-top="56" data-sticky-wrap>
            <div className="w-100">
              <div id="pythagorasBabylon" className="svg-wrapper d-flex justify-content-center" ></div>
            </div>
          </div>
        </div>
        <div className="col-md-6"><p>The origins of Geometry are unknown because it predates written history. Our best guess is that it came out of the necessity of measuring farm land in the Egytian and Bablyonian empires as far back as over four-thousand years ago. An early Babylonian tablet contains a field measuring problem where starting with a 
        <Mag color="red" highlightLabel="smallSquare" fill="white" >small square</Mag>, if on that square's
        <Mag color="red" highlightLabel="smallDiagonal" >diagonal</Mag> another 
        <Mag color="red" highlightLabel="middleSquare" fill="white" >middle square</Mag> is drawn, then that 
        <Mag color="red" highlightLabel="middleSquare" fill="white" >middle square</Mag> will be double the area of the
        <Mag color="red" highlightLabel="smallSquare" fill="white" >small square</Mag>. This can be proven because the middle square is made up of 
        <Mag color="red" highlightLabel="fourTriangles" >four equal triangles</Mag>
        while the small square is made up of
        <Mag color="red" highlightLabel="twoTriangles" >two equal Triangles</Mag>. And since 4 is double 2, then that means the <Mag color="red" highlightLabel="middleSquare" fill="white">middle square</Mag> is double the area of the 
        <Mag color="red" highlightLabel="smallSquare" fill="white">small square</Mag>.</p>
        </div>
      </div>
      <div className="row py-5">
        <div className="col-md-6 d-flex align-items-center">
          <p>Another early geometry insight states that if a triangle is drawn with one of its sides on the diameter of a circle, then no matter where you drag the top angle, it will always be 90 degrees. This fact is known as Thales' Theorem. Thales was an ancient Greek geometer from 600 BC, but the truth is we don't know if he actually discovered it. Thales also studied in Egypt and Babylon, so it could be that he discovered it from them.</p>

        </div>
        <div className="col-md-6">
          <div className="">
            <div id="thales" className="svg-wrapper d-flex justify-content-center"></div>
            <p className="small">Because the triangle's base is on the diameter of the circle, no matter where you drag the top angle, it will always be 90 degrees.</p>
          </div>
        </div>
      </div>
      
    </div>
    
    <div className="align-items-center row m-0">
      
      <div className="col-md half-container order-md-2 m-auto m-md-0">
        <p>The Egyptians knew about the 3-4-5 right-triangle, where if the sides of a triangle where 3 units, 4 units and 5 units, then a perfect right-triangle would be formed everytime. </p>
        <div id="triangle345" class="d-flex justify-content-center"></div>
        <p>The Egyptians thought this had mystical conotations, so they associated their gods, Isis, Osiris and Horus with the numbers 3, 4 and 5. One historian suggests that Egyptian land surveyors may have learned of  3-4-5 triangles in order to lay out accurate right angles with knotted cords, which they were known to use.</p>
        
      </div>
      <div className="col-md-6 p-0 pr-sm-5 ">
        <img className="w-100 " src="images/home/egyptRope-sm2.jpg" alt="" />
      </div>

    </div>
    <div className="container pt-5">
      <div className="align-items-center row">
        <div className="col-md-6 d-flex justify-content-center ">
          <div id="pythagoras345" className="svg-wrapper"></div>
        </div>
        <div className="col-md-6 container">
          <p>In time it would be discovered that if you draw squares on each of the sides of a right triangle, that the <Mag highlightLabel="legsSquares" >squares</Mag> on the <Mag highlightLabel="legsHighlight" >legs</Mag> will add up to the 
            <Mag highlightLabel="rectHighlight" >square</Mag> on the <Mag highlightLabel="hypotenuseHighlight">hypotenuse</Mag>. As in the case of the 3-4-5 right-triangle in this section, the sum of the areas of the yellow squares is equal to the area of the blue square, since they both add up to 25. This phenomenon can be expressed as 
          ​​<span style={{whiteSpace:'nowrap'}}>a​​<sup>2</sup>​ + b​​<sup>2</sup> = c<sup>2</sup></span>, also known as the Pythagorean Theorem, named after the greek mathematian Pythagoras. But the truth is we don't know if he actually discovered it.
          </p>
        </div>

      </div>
      <div className="row">
        <div className="col-md-10 col-lg-8 col-xl-6 pt-5 m-auto">
          <p>It could be that Pythagoras merely learned of the proof from somewhere else. To give an example of how that may be, below shows a diagram similar to the Babylonian proof at the top of the page. One can imagine someone becoming curious about changing the size of the middle square so its corners don't fall on the mid-points of the larger square anymore.</p>
        </div>
      </div>
    </div>
    <div class="container pythagoras-diagrams-wrapper" data-sticky-container >
      <div className="mx-auto row sticky" data-sticky-wrap data-margin-top="56" >
        <div className="col d-flex justify-content-end p-0">
          <div id="pythagoras" className="svg-wrapper"></div>
        </div>
        <div className="col d-flex  p-0">
          <div id="pythagorasDisected" className="svg-wrapper"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 col-lg-8 col-xl-6 pb-5 m-auto">
            
          <p> If you adjust the middle square's position, you will see that 
            <Mag highlightLabel="cornerPieceGroup">4 corner-pieces</Mag> are created, that if they're <Mag highlightLabel="cornerPieceHighlight2">re-arranged</Mag>, they create
            <Mag highlightLabel="squareAsquareB"> two squares</Mag>
             on <Mag highlightLabel="highlightLegsPythag">each of the legs</Mag> of one of the corner-pieces. And since nothing is being added or removed from <Mag highlightLabel="largeSquare1">the large square</Mag>, that means the whitespace labeled 
            <Mag highlightLabel="squareC">​​c​​<sup>2</sup></Mag>​​, 
            is equal to the sum of the white spaces  
            ​​<Mag highlightLabel="squareAsquareB">a​​<sup>2</sup>​ + b​​<sup>2</sup></Mag>.</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div className="align-items-center row">
        <div className="col-md-6 order-md-2 d-flex justify-content-center">
          <div id="pythagorasThalesCombo"  className="svg-wrapper"></div>
        </div>
        <div className="col-md-6 order-md-1 pt-4">
          <p>And if you combine Thales Theorem with the Pythagorean Theorem, you can animate the sum of the squares for every single possible right triangle. While the ancient Greeks didn't have computers to easily animate their diagrams, their proofs show they were aware that this animation would be possible. They were amazed with the coincidence that logic could describe the magical properties of shapes and numbers. And that is the spirit in which the <i>Geometre's Grimoire</i> is written, to convey to you the fascination that early human-kind had with these geometric revelations.</p> 
          <p className="py-3 text-center"><a className="btn btn-primary btn-lg" target="_blank" href="https://www.amazon.com/dp/B08F719JW3" role="button">Check out the book</a></p>
        </div>
      </div>
      </div>
    </div>
  </div>
  }
}