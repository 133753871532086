import * as React from "react";

function SvgOpticsSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
      id="my-svg"
      viewBox="0 0 288 160" {...props}>
      <style>
        {
          ".st1{fill:none;stroke:#000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:5.2364}.st2{font-family:&apos;ArialMT&apos;}.st3{font-size:13.0909px}.st4{fill:none}.st6{fill:none}"
        }
      </style>
      <g id="eye">
        <circle
          cx={271.1}
          cy={142.6}
          r={9.3}
          fill="#fff"
          stroke="#000"
          strokeWidth={1.309}
          strokeLinecap="round"
          strokeMiterlimit={5.236}
        />
        <path d="M263.8 137c1.5-1.1 3.7 1.7 3.7 4.9 0 3.1-1.8 6.3-3.7 5-2.7-1.5-3.2-7.7 0-9.9z" />
      </g>
      <g id="StaticDiagram">
        <path
          id="TriangleEFD"
          className="st1"
          d="M28.4 142.1V25.3l235.1 117.1-235.1-.3"
        />
        <path
          id="SectorGBH"
          className="st1"
          d="M133.4 142.2c0-52.9 33.8-87.7 38.5-92.3l91.6 92.3H133.4z"
        />
        <path
          id="TriangleACD"
          className="st1"
          d="M263.5 142.4l-116-.3V25.3l116 117.1"
        />
      </g>
      <g id="labels">
        <text
          transform="translate(138.764 21.01)"
          className="st2 st3"
        >
          {"A"}
        </text>
        <text
          transform="translate(136.455 76.61)"
          className="st2 st3"
        >
          {"B"}
        </text>
        <text
          transform="translate(144 154.846)"
          className="st2 st3"
        >
          {"C"}
        </text>
        <text
          transform="translate(248.727 154.846)"
          className="st2 st3"
        >
          {"D"}
        </text>
        <text
          transform="translate(20.946 22.01)"
          className="st2 st3"
        >
          {"E"}
        </text>
        <text
          transform="translate(20.946 154.846)"
          className="st2 st3"
        >
          {"F"}
        </text>
        <text
          transform="translate(172.8 49.81)"
          className="st2 st3"
        >
          {"G"}
        </text>
        <text
          transform="translate(128.291 154.846)"
          className="st2 st3"
        >
          {"H"}
        </text>
      </g>
      <g id="highlightParts">
        <path
          id="lineED"
          className="st4"
          d="M28.4 25.3l235.1 117.1"
        />
        <path
          id="lineAD"
          className="st4"
          d="M147.5 25.3l116 117.1"
        />
        <path
          id="lineBD"
          className="st4"
          d="M147.5 84.6l116 57.8"
        />
        <path
          id="lineBC"
          className="st4"
          d="M147.3 142.4V84.9"
        />
        <path
          id="lineAC"
          className="st4"
          d="M147.3 142.1V25.3"
        />
        <path
          id="lineEF"
          className="st4"
          d="M28.4 142.1V25.3"
        />
        <path
          id="triangleADC"
          className="st4"
          d="M263.5 142.4l-116-.3V25.3l116 117.1"
        />
        <path
          id="lineFD"
          className="st4"
          d="M263.5 142.4l-235.1-.3"
        />
        <path
          id="lineDC"
          className="st4"
          d="M263.5 142.4l-116.2-.3"
        />
        <path
          id="sectorGDH"
          className="st4"
          d="M133.4 142.2c0-52.9 33.8-87.7 38.5-92.3l91.6 92.3H133.4z"
        />
        <path
          id="sectorGDB"
          className="st4"
          d="M263.5 142.4l-91.6-92.3c-2.6 2.6-14.5 14.8-24.5 34.4l116.1 57.9"
        />
        <path
          id="triangleADB"
          className="st4"
          d="M147.5 84.4l116 57.9c-38.6-39-77.4-77.9-116-116.9v59"
        />
        <path
          id="triangleBDC"
          className="st4"
          d="M147.5 142.1V84.5l116 57.9-116-.3"
        />
        <path
          id="sectorBDH"
          className="st4"
          d="M263.5 142.4l-130.1-.1c0-23 6.4-42.5 14-57.7l116.1 57.8z"
        />
        <path id="angleGDH" className="st6" d="M197,142.2c0-27,17.3-44.8,19.7-47.2l46.8,47.2H197z"/>
	      <path id="angleBDH" className="st6" d="M263.5,142.4l-45.6,0c0-8.1,2.2-14.9,4.9-20.2L263.5,142.4z"/>
      </g>
    </svg>
  );
}

export default SvgOpticsSvg;

