import React from 'react';
import Magnitude from './magnitude';

export default class Fraction2 extends React.Component {

  state={
    freezeHighlight:0,
    highlightMagnitudes: [0, 0]
  }
  
  render() {

    let ratioParts = this.props.children;
    let noHighlight = 0;
    if (ratioParts[0] && ratioParts[0].type !== Magnitude && ratioParts[1] && ratioParts[1].type !== Magnitude ) {
      noHighlight = 1;
    }

    return <span className={`frac 
    ${noHighlight? "noHighlight": ""}
    ${this.state.freezeHighlight? "clickHighlight": ""}`}
 /*   
      onClick={(e)=>{
        if(e.target.className.match("frac") || e.target.className == "symbol") {
          this.setState((curState)=>{
            return {
              ...curState,
              freezeHighlight: (!curState.freezeHighlight)
            }
          })
        }
      }}
*/
      onMouseOver={(e)=>{
        if(e.target.className.match("frac") || e.target.className == "symbol") {
          this.setState({
            highlightMagnitudes:[1,1]
          })
        } else {
          let top = e.target.className.match('top')
          let bottom = e.target.className.match('bottom')

          this.setState((prevState)=>({
            ...prevState,
            highlightMagnitudes:[top,bottom]
          }))
        }
      }}

      onMouseOut={(e)=>{ 
        if(!this.state.freezeHighlight) {
          this.setState((prevState)=>({
            ...prevState,
            highlightMagnitudes:[0,0]
          }))
        }
      }}
    >
      
      {ratioParts[0] ? React.cloneElement(ratioParts[0] , { 
            className: "top",
            highlightRatio: this.state.highlightMagnitudes[0],
            freezeHighlight: this.state.freezeHighlight
      } ) : "Missing value" }

      <span className="symbol">/</span>

      {ratioParts[1] ? React.cloneElement(ratioParts[1] , { 
            className: "bottom",
            highlightRatio: this.state.highlightMagnitudes[1],
            freezeHighlight: this.state.freezeHighlight
      } ) : "Missing value"}
      
    </span>  
  }
}