import React from 'react';
import Fraction from '../../../fraction2'
import Note from '../../../note';
import Magnitude from '../../../magnitude'

export default function Step3(){
  let text="and when put together, the triangle ADC, compared with the triangle BDC has a greater ratio than the sector GDH, compared with sector BDH."

  return <div className="step" id="Step3">
  <p>3. {text}</p>
  <p>Starting from the last expression in step 2, <br />
    add the bottom to the top of each ratio:<br />
    <Fraction>
      <Magnitude highlightLabel="Whole triangle ADC">​​triangle ADB + triangle BDC</Magnitude>
      <Magnitude>triangle BDC</Magnitude>
    </Fraction>
    <span> &gt; </span>
    <Fraction>
      <Magnitude highlightLabel="Whole sector GDH">sector GDB + sector BDH</Magnitude>
      <Magnitude>sector BDH</Magnitude>
    </Fraction>
  </p>

  <p>Which when added up it becomes:<br />
    <Fraction>
      <Magnitude>Whole triangle ADC</Magnitude>
      <Magnitude>Bottom triangle BDC</Magnitude>  
    </Fraction> 
      
      <span> &gt; </span>
    <Fraction>
      <Magnitude>Whole sector GDH</Magnitude>
      <Magnitude>Bottom sector BDH</Magnitude>
    </Fraction>

  </p>
  <Note>
    <p>Use componendo rule to add the bottom to the top to obtain the whole. This is when we first get a sense of Euclid's intentions for this inequality as its ratios now contain the triangles that the posts are seen through.</p>
  </Note>
  </div>
}