import React from 'react';

export default class Note extends React.Component {
  
  state={
    showNote:0
  }

  constructor(props) {
    super(props)
    this.myRef = React.createRef()  
  }
  
  executeScroll = () => {
    var y = window.scrollY;
    let item = this.myRef.current// what we want to scroll to
    let offset = item.offsetTop + document.querySelector(".navbar").offsetHeight - 10;// the wrapper we will scroll inside
    // If the point to scroll to is already visible then don't scroll.
    if(offset < y) {
      window.scrollTo(0, offset);
    }
  }
    // run this method to execute scrolling. 

  render () {
   
    return <div className="noteWrapper">
      <a href="#" ref={this.myRef} onClick={(e)=>{ 
        this.setState((curState) => ({
          showNote: !curState.showNote
        }))
        e.preventDefault();
      }} >{this.state.showNote ? 'Hide ' : 'Show ' }   
        {this.props.note ? this.props.note : "note"}</a>
      <div className={`noteContentWrapper ${this.state.showNote ? 'show' : 'hide' }`}>
        <div className="noteContent">{this.props.children}</div>
        <a href="#" onClick={(e)=>{ 
          this.setState((curState) => ({
            showNote: !curState.showNote
          }))
          this.executeScroll()
          e.preventDefault();
        }} >{this.state.showNote ? 'Hide ' : 'Show ' }   
          {this.props.note ? this.props.note : "note"}</a>
      </div>
    </div>  
  }
}