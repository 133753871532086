import React from 'react';
import Fraction from '../../../fraction2'
import Note from '../../../note';
import Mag from '../../../magnitude'

export default function Step3(){
  let text="Now, since the triangle ADB > section GDB, and since the triangle BDC <  section BDH, the triangle ADB, compared with the section GDB, has a greater ratio than the triangle BDC, when compared with the section BDH."

  return <div className="step" id="Step3">
    <p>Next draw a circle around your head where the radius is one of your sight-lines, and then create an equilateral triangle within the circle.</p>
    <p>Since <Mag>Line AC</Mag> = <Mag>Line DF</Mag>, both being equal to a side of <Mag>equilateral Triangle DAF</Mag>, then <Mag>Line DF</Mag> can be substituted into the proportion from the last step.</p>
    <p>So that this:&nbsp;
      <Fraction>
        <Mag>Line EC</Mag>
        <Mag>Line AB</Mag>
      </Fraction>
        <span> = </span>
      <Fraction>
        <Mag>Line CD</Mag>
        <Mag>Line AC</Mag>
      </Fraction>
      </p>
    <p>becomes this:&nbsp;
      <Fraction>
        <Mag>Line EC</Mag>
        <Mag>Line AB</Mag>
      </Fraction>
        <span> = </span>
      <Fraction>
        <Mag>Line CD</Mag>
        <Mag>Line DF</Mag>
      </Fraction>
    </p>

    <p>​This proportion shows that the chord of our sight-lines on the Moon to the chord of the equilateral triangle is the same as comparing the Moon's  diameter to its distance from our eye.</p>

    <p>Also, <Mag>Angle CAD</Mag> is 1/2 of a degree, and <Mag>Angle DAF</Mag> is 60 degrees since all angles of an equilateral triangle are 60 degrees.</p>
    <p>Thus ​​ 
      <Fraction>
        <Mag>Angle CAD</Mag>
        <Mag>Angle DAF</Mag>
      </Fraction>
      <span> = </span>
      <Fraction  top="1/2" bottom="60">
        <>1/2</>
        <>60</>  
      </Fraction> .</p>  
    <p>Multiply both parts of the fraction by 2 so the numerator is  1,  which will give us 1/120 . This will make for easier calculations later on. So we have a ratio of the angles that correspond to <Mag>Line CD</Mag> and <Mag>Line DF</Mag>, lines that are analogous to the Moon's diameter and its distance from our eye.</p> 
  </div>
}