import Step1 from './steps/step1'
import Step2 from './steps/step2'
import Step3 from './steps/step3'
import Step4 from './steps/step4'
import Step5 from './steps/step5'
import Step6 from './steps/step6'
import Note from '../../note'
import { Component } from 'react'
import OpticsSvg from './opticsSvg'
import Mag from '../../magnitude'
import Fraction from "../../fraction2";

export default class Optics extends Component {

  componentDidMount(){
    window.customSvgOptics();
  }

  render() {
  return (
    <div id="opticsPage" className="container page">
      <div className="row d-sm-none"><div className="col">
        <p>Sorry, this page isn't designed for this screen resolution.</p>
        </div>
      </div>
      <div className="row d-none d-sm-flex">
      <div className="col-sm">
          <div id="svgWrapper">
            <OpticsSvg/>
          </div>
        </div>
        <div className="col-sm">
          
          <h2>Euclid's Optics: Proposition 8</h2>
          <p><i>Lines of equal length and parallel, if placed at unequal distances from the eye, are not seen in proportion to the distances.</i></p>
          <Note>
            <img className="left" width="50%" src="images/optics/perspective-trattato.jpg" />

            <p>It's not clear what Euclid's purpose was for this proof. Perhaps it was to sort out the confusion of seeing things in perspective. Without analysis, one might assume objects do shrink proportional to their distance since they appear to. But that's actually just the illusion of perspective where objects shrink relative to a vanishing point way off on the horizon.  Another possible reason for this statement is that it would have been much easier for mathematicians  if objects did shrink in proportion to their distance because that would make measuring objects off in the distance easier. So perhaps this statement is meant to refute that belief. </p>
            <div className="clear"></div>
          </Note>

          <p>Construction:<br/>
            Let there  be two posts, <Mag highlightLabel="line EF" >Post EF</Mag> and <Mag highlightLabel="line AC" >Post AC</Mag>, equal in height, but unequally distant from the eye at point D. 
            <Mag highlightLabel="line FD" >Distance FD</Mag> is not in the same relation to <Mag highlightLabel="line DC" >Distance DC</Mag> as the angles the <Mag highlightLabel="line AC" >Post AC</Mag> and <Mag highlightLabel="line EF" >Post EF</Mag> are seen through. Or more specifically:<br/> 
            <Fraction>
              <Mag highlightLabel="line FD" >Distance FD</Mag>
              <Mag highlightLabel="line DC" >Distance DC</Mag> 
            </Fraction>  <span> &gt; </span>
            <Fraction>
              <Mag>Whole angle GDH</Mag>
              <Mag>Bottom angle BDH</Mag> 
            </Fraction>
. <br/>For let the rays fall, <Mag highlightLabel="line ED">sight-line ED</Mag> and <Mag highlightLabel="line AD">sight-line AD</Mag>, from the eye at point D. Point B marks where <Mag highlightLabel="line ED">sight-line ED</Mag> intersects with <Mag highlightLabel="line AC">Post AC</Mag>.  Use <Mag>line BD</Mag> as the radius to create <Mag>sector GDH</Mag>.</p>
          
          <Note>
            <p>When viewed in perspective, <Mag highlightLabel="line EF" >Post EF</Mag> will appear as tall as <Mag>line BC</Mag>.</p>
            <div><img width="100%" src="images/optics/albrectDurer.jpg" /></div>
            <p><img className="left" width="40%" src="images/optics/lute.jpg" />Renaissance draughtsmen used this trick to make photo realistic drawings by viewing an object through a grid, which acts as a visual plane, a cross-section of the draughtman's sight-lines. All the draughtman needs to do is transfer the image of the guitar as it appears on the visual plane onto a piece of paper with a matching grid drawn on it.</p>
            <p>So in a sense, <Mag highlightLabel="line AC" >Post AC</Mag> acts as the visual plane through which we view <Mag highlightLabel="line EF" >Post EF</Mag>. So <Mag>Line BC</Mag> is how tall <Mag highlightLabel="line EF" >Post EF</Mag> would appear to us. </p>
            <p>The wording of the proposition is a little weird. A better way to describe the proof is it first describes the relationship of the posts as they are seen, <Mag highlightLabel="line AC" >Post AC</Mag> and <Mag>line BC</Mag>, to the angles they are seen through. And then Euclid will show that the posts as they are seen in relation to each other is proportional to their distances from our eye. Hence whatever can be said about the posts as they are seen in relation to the angles they are seen through can also be said about their distances from our eye.</p>
            <img className="left" width="40%" src="images/optics/sector.jpg"/>
            <p>You'll notice the proof's drawing uses a sector. A sector is a section of a circle like a piece of pie cut from the center. It can be thought of as the visible analogy of an angle because a sector's area increases proportionally to its angle. For example, if you had a pie and you cut out a sector whose area is a quarter of the pie, it's angle would be 90°. There's 360° in a circle, and 90° is a quarter of 360°. If the size of the sector were increased to be half of the pie, it's angle would be 180°, which is half of the degrees in a circle.</p>
            <div className="clear"></div>
            <img className="left" width="40%" src="images/optics/sectorProportion.jpg"/>
            <p>A sector's relationship to it's angle is like a triangle's area in relation to it's base line, since the triangle's area increases proportionally to it's baseline. Another way they are similar is that a triangle's area is the visible analogy to the baseline. In this proof, a triangle's baseline will be compared to it's opposite angle by transforming the baseline to its analgous triangle area, while the angle will be transformed into its analogous sector. It's as though the two invisible magnitudes needed to become visible in order to be compared. And what we will see is that sectors are not in proportion to triangles.</p>

          </Note>
          
          <Step1/>
          <Step2/>
          <Step3/>
          <Step4/>
          <Step5/>
          <Step6/>
        </div>
        
      </div>
    </div>
  );
  }
}
