import React from 'react';

import Step1 from './steps/step1'
import Step2 from './steps/step2'
import Step3 from './steps/step3'
import Step4 from './steps/step4'
import Step5 from './steps/step5'

import Moon2Svg from './moon2Svg'

let steps = [ Step1, Step2, Step3, Step4, Step5 ];

export default class MoonPart2 extends React.Component {
  
  state={
    step:1
  }

  broadcastMessage(step, type) {
    var event = new CustomEvent("step", {'detail': {
      step, 
      type
    }});
    document.dispatchEvent(event);
    window.scrollTo(0,0);
  }

  nextStepAnimation = () => {
  
    const step =  this.state.step+1; 
    if(step <= 5) {
      this.setState({
          step 
        }
      )
      this.broadcastMessage(step, "next");
    }
  }

  previousStepAnimation = () => {
  
    const step =  this.state.step-1; 
    if(step > 0) {
      this.setState({
          step 
        }
      )
      this.broadcastMessage(step, "previous")
    }
  }

  componentDidMount(){
    window.customSvgMoon2();
  }

  render() {
    return (
      <div className="container page">
        <div className="row d-sm-none"><div className="col">
            <p>Sorry, this page isn't designed for this screen resolution.</p>
          </div>
        </div>
        <div className="row d-none d-sm-flex">
          <div className="col-sm">
            <Moon2Svg date={new Date().getTime()} />
          </div>
          <div className="App col-sm">
            <h2>Aristarchus' Moon Proof, Part 2</h2>
            <p>The least the Moon diameter can be in relation to it's distance from your&nbsp;eye.</p>
            
            <p><b>Step {this.state.step} of 5</b></p>
            { this.state.step == 1? <Step1/>: "" }
            { this.state.step == 2? <Step2/>: "" }
            { this.state.step == 3? <Step3/>: "" }
            { this.state.step == 4? <Step4/>: "" }
            { this.state.step == 5? <Step5/>: "" }

            { this.state.step > 1? 
              <button onClick={ this.previousStepAnimation } >Previous Step</button> 
              : "" }
            &nbsp;&nbsp;
            { this.state.step < 5? 
              <button onClick={ this.nextStepAnimation } >Next Step</button> 
              : "" }
            
          </div>
        </div>
      </div>
    );
  }
}


