import React from 'react';
import Fraction from '../../../fraction2';
import Note from '../../../note';
import Magnitude from '../../../magnitude';

export default function Step2(){
  let text="And, alternately, the triangle ADB, compared with the triangle BDC, has a greater ratio than the sector GDB has when compared with the sector BDH,"
  return <div className="step" id="Step2">
    <p>2. {text}</p>
    <p>Starting from the expression in Step 1:</p>
    <ul>    
    <li><b>Alternate the extremes:</b> <br />
      <Fraction>
        <Magnitude>greater sector BDH</Magnitude>
        <Magnitude>smaller sector GDB</Magnitude>
      </Fraction>
      <span> &gt; </span>
      <Fraction>
        <Magnitude>smaller triangle BDC</Magnitude>
        <Magnitude>greater triangle ADB</Magnitude>
      </Fraction>
    </li>
    <li><b>Inverse:</b> <br />
      <Fraction>
        <Magnitude>​​smaller sector GDB</Magnitude>
        <Magnitude>greater sector BDH</Magnitude>
      </Fraction>
      <span> &lt; </span>
      <Fraction>
        <Magnitude>​​greater triangle ADB</Magnitude>
        <Magnitude>smaller triangle BDC</Magnitude>
      </Fraction>
            
      <p>Notice the inequality sign changes</p>
    </li>
    <li><b>Switch positions of ratios:</b> <br />
      <Fraction>
        <Magnitude>​​greater triangle ADB</Magnitude>
        <Magnitude>smaller triangle BDC</Magnitude>
      </Fraction>
      <span> &gt; </span>
      <Fraction>
        <Magnitude>​​smaller sector GDB</Magnitude>
        <Magnitude>greater sector BDH</Magnitude>
      </Fraction>

      <p>Notice the inequality sign changes back</p>
    </li>
    </ul>
    <Note>
      <p>This is just shuffling around the expression for step 3.</p>
      <p>It's possible to reach this final expression directly by relating the geometry to numbers</p>
      <p><img className="left" src="images/optics/triangleUnitInequality.jpg"/>Compose 2 triangles with a shared apex and whose areas are equal to 10 each, so that the triangles have a ratio of ​​ 
      <Fraction>
        <>10</>
        <>10</>
      </Fraction>
      , which is the same as 1.</p>
      <div className="clear"></div>

      <p><img className="left" src="images/optics/triangleUnitInequalitysection.jpg"/>Apply the sector to the triangle. This is basically the same as decreasing the numerator and increasing the denominator, like if 
      <Fraction>
        <>10</>
        <>10</>
      </Fraction> where transformed to 
      <Fraction>
        <>8</>
        <>12</>
      </Fraction>, which is less than 1, demonstrating that <br /><br />
        <Fraction>
          <>top triangle</>
          <>bottom triangle</>
        </Fraction>
        <span> &gt; </span>
        <Fraction>
          <>top sector</>
          <>bottom sector</>
        </Fraction>
      </p>
      <div className="clear"></div>
    </Note>
  </div>
}