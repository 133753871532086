import React from 'react';
import Fraction from '../../../fraction2'
import Note from '../../../note';
import Mag from '../../../magnitude';

export default function Step5(){
  
  return <div className="step" id="Step5">
      <p>So we know from the last step that&nbsp; 

        <Fraction>
          <Mag>Angle CAD</Mag>
          <Mag>Angle DAF</Mag>
        </Fraction>
          <span> &lt; </span>
        <Fraction>
          <Mag>Line CD</Mag>
          <Mag>Line DF</Mag>
        </Fraction>
      </p>
      <p>This final step involves substituting known values into that inequality.</p>
      <p>We know from 2 steps back that&nbsp;
         <Fraction>
          <Mag>Angle CAD</Mag>
          <Mag>Angle DAF</Mag>
         </Fraction>
        <span> = </span>
        <Fraction>
          <>1</>
          <>120</>
        </Fraction>,</p>
      <p>so 
        <Fraction>
          <>1</>
          <>120</>
        </Fraction> can be substituted into the inequality:<br/><br/>
        <Fraction>
          <>1</>
          <>120</>
        </Fraction>
        <span> &lt; </span>
        <Fraction>
          <Mag>Line CD</Mag>
          <Mag>Line DF</Mag>
        </Fraction>
      </p>

      <p>And we know that
        <Fraction>
          <Mag>Line CD</Mag>
          <Mag>Line DF</Mag>
        </Fraction>
        <span> = </span>
        <Fraction>
          <Mag>Line EC</Mag>
          <Mag>Line AB</Mag>
        </Fraction>
        , so 
        <Fraction>
          <Mag>Line EC</Mag>
          <Mag>Line AB</Mag>
        </Fraction>
        can be substituted into the inequality too:​​</p>
      <p>
        <Fraction>
          <>1</>
          <>120</>
        </Fraction>
        <span> &lt; </span>
        <Fraction>
          <Mag>Line EC</Mag>
          <Mag>Line AB</Mag>
        </Fraction>
      </p>
      <p>And once again, <Mag>Line EC</Mag> is the Moon's diameter, <br/>and <Mag>Line AB</Mag> is distance of the Moon's center from your eye. </p>
      
      <p>By alternating the extremes, this proportion can also be written as
        <Fraction>
          <Mag>Line AB</Mag>
          <>120</>
        </Fraction>
        <span> &lt; </span>
        <Fraction>
          <Mag>Line EC</Mag>
          <>1</>
        </Fraction>
      </p>
      <p>Which corresponds to the following statement that the diameter of the Moon is greater than a ​<Fraction>
          <>1</>
          <>120</>
        </Fraction>​​th of the distance of the Moon's center from your eye.</p>
      <p>End of Proof.</p>
  </div>
}