import React, { Fragment } from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'
import Nav from "./components/Nav"
import Contact from './components/pages/Contact'
import Corrections from './components/pages/Corrections'
import HomePage from './components/pages/homePage/HomePage'
import Optics from './components/pages/optics/optics'
import MoonPart2 from './components/pages/moonPart2/MoonPart2'
import ScrollToTop from './components/ScrollToTop';
import PrivacyPolicy from './components/pages/PrivacyPage'

import { NavLink } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      

      <Router>
      <ScrollToTop />
      <Fragment>
          <Nav />
          <div className="content-container">
            <Route path={`/`} exact component={HomePage} />
            <Route path={`/corrections`} component={Corrections} /> 
            <Route path={`/contact`} component={Contact} /> 
            <Route path={`/euclids-optics`} component={Optics} />
            <Route path={`/moon-proof-2`} component={MoonPart2} />
            <Route path={`/privacy-policy`} component={PrivacyPolicy} />
          </div> 
          <div className="footer"><NavLink to={`/privacy-policy`}>Privacy Policy</NavLink></div>
      </Fragment>
    </Router>

    </div>
  );
}

export default App;
