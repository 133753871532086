import * as React from "react";

function SvgIcon(props) {
  return (
    <svg
      id="my-svg"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 836 725.7"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".st0,.st1{stroke-width:1.75;stroke-linejoin:round}.st4{font-size:20px;font-weight:600}.st5{fill:none;stroke:#231f20;stroke-width:1.75;stroke-linejoin:round}.st13{fill:none;stroke:#000;stroke-miterlimit:10;stroke-dasharray:7.8224,7.8224}#sektorTest .Sektor-sector { stroke: #231f20; }"
        }
      </style>
      <g id="container">
      <g id="sektorTest"></g> 
        <g id="inequality">
          <path
            className="st1"
            fill="none"
            stroke="#231f20"
            d="M389 17L129.3 465.2V266.4zM254.8 238.5l531.8 308"
          />
          <path
            className="st1"
            fill="none"
            stroke="#231f20"
            d="M129.3 465.2L786 546 389 17 130 462M129.3 266.4L786 546"
          />
          <circle
            className="st1"
            cx={475.4}
            cy={365}
            r={359.8}
            fill="none"
            stroke="#231f20"
          />
          <path
            className="st1"
            d="M177.2 471.2c10.1-83.9 37.2-162.6 77.6-232.6"
            fill="none"
            stroke="#231f20"
          />
          <g id="angleCAD">
            <path
              className="st13"
              d="M474.6 365.1l-85.9-348M140 462.8l327.6-94.4M463.8 362.9l-326.9-94.3"
            />
          </g>
        </g>
        <g id="aristarchusMoon2">
          <g id="Eye">
            <circle
              className="st0"
              cx={486.6}
              cy={367.7}
              r={14.2}
              fill="#FFF"
              stroke="#231F20"
            />
            <path
              className="st1"
              d="M474.4 372.3c1.7 1.5 4.5-1.5 4.9-5.3.4-3.6-1.3-7.5-3.7-6.4-3.1 1.4-4.5 8.7-1.2 11.7z"
            />
          </g>
          <g id="Moon">
            <circle
              id="moonCircle"
              className="st5"
              cx={104.2}
              cy={365.5}
              r={103.3}
            />
            <text
              transform="translate(20.55 412.42)"
              className="st2"
              fontSize={20}
            >
              {"Moon"}
            </text>
          </g>
          <path
            className="st5 points"
            d="M128.6 466.1l346.6-99.9-345-100.6"
            id="sightlines_on_moon"
          />
          <g id="thalesRectangle">
            <path
              data-start={0}
              data-duration={100}
              id="thalesRectangleRect"
              className="st5"
              d="M129.3 266.4v198.8H77.9V266.4h51.4"
            />
            <path
              data-start={120}
              data-duration={40}
              fill="none"
              stroke="#231f20"
              strokeWidth={1.75}
              strokeMiterlimit={10}
              d="M78.1 265.5l50.5 200.4"
            />
          </g>
          <g id="thalesRectangleTransition">
            <path
              id="thalesWaste"
              strokeWidth={1.75}
              className="st17"
              fill="none"
              d="M128.4 465.2H77.9V266.4h.4z"
            />
            <path
              id="thalesKeep"
              strokeWidth={1.75}
              className="st17"
              fill="none"
              d="M129.3 266.4v198.8h-.9L78.3 266.4z"
            />
          </g>
          <g id="centerSightlines">
            <line
              data-duration={40}
              fill="none"
              x1="474.9" y1="366.1" x2="104.2" y2="365.4"
            />
          </g>
          <circle
            fill="none"
            className="st12"
            cx={475.4}
            cy={365}
            r={359.8}
            id="visionCircle"
          />
          <path
            fill="none"
            className="st14"
            d="M474.6 365.1l-85.9-348-258.4 248.4"
            id="equilateralTriangle"
          />
          <g id="thalesRectangleParts">
            <path
              className="st17"
              id="longLeg"
              d="M129.3 266.4v198.8"
            />
            <path
              className="st17"
              id="shortLeg"
              d="M78.3 266.4h51"
            />
            <path
              className="st17"
              id="hypotenuse"
              d="M128.4 465.2L78.3 266.4"
            />
          </g>
        </g>
        <g className="labels">
          <text
            transform="translate(459.44 390)"
            fontSize={16}
            className="labelA st2 st3 st4"
          >
            {"A"}
          </text>
          <text
            transform="translate(87 372)"
            fill="none"
            fontSize={14}
            className="labelB st9 st3 st4"
          >
            {"B"}
          </text>
          <text
            transform="translate(116.44 485)"
            fontSize={14}
            className="labelC st2 st3 st4"
          >
            {"C"}
          </text>
          <text
            transform="translate(113.44 257.09)"
            fontSize={20}
            className="labelD st2 st3 st4"
          >
            {"D"}
          </text>
          <text
            transform="translate(73.44 257.09)"
            fill="none"
            fontSize={14}
            className="labelE st9 st3 st4"
          >
            {"E"}
          </text>
          <text
            transform="translate(379 15)"
            fill="none"
            fontSize={14}
            className="labelF st2 st3 st4"
          >
            {"F"}
          </text>
          <text
            transform="translate(134,384)"
            fill="none"
            fontSize={14}
            className="labelH st9 st3 st4"
          >
            {"H"}
          </text>
        </g>
        <g id="rotatingLines">
          <path
              id="lineAF"
              stroke="none"
              className="st3"
              d="M474.6 365.1l-85.9-348"
            />
            <path
              id="lineDA"
              stroke="none"
              className="st0"
              d="M475.2 366.2l-345.9-99.8"
            />
        </g>
        <g id="highlightParts">
          <g id="lines">
            <path
              id="lineDF"
              stroke="none"
              className=""
              d="M388.7 17.1L130.3 265.5"
            />
            <path
              id="lineCD"
              stroke="none"
              className=""
              d="M129.3 465.2V266.4"
            />
            <path
              id="lineAB"
              stroke="none"
              className=""
              d="M475.2 366.2l-371.9-.8"
            />
            <path
              id="lineAC"
              stroke="none"
              className=""
              d="M128.6 466.1l346.6-99.9"
            />
            <path
              id="lineEC"
              stroke="none"
              className=""
              d="M78.3 266.4l50.1 198.8"
            />
          </g>
          <g id="angles">
            <path
              id="angleDAF"
              stroke="none"
              className=""
              fill="none"
              d="M130.3 265.5l344.3 99.6-85.9-348"
            />
            <path
              id="angleCAD"
              stroke="none"
              className=""
              fill="none"
              d="M128.6 466.1l346.6-99.9-345.9-99.8"
            />
            <path
              id="angleBAC"
              stroke="none"
              className=""
              fill="none"
              d="M128.6 466.1l346.6-99.9-371.9-.8"
            />
            <path
              id="angleEDC"
              stroke="none"
              className=""
              fill="none"
              d="M78.3 266.4h51v198.8"
            />
            <path
              id="angleBCA"
              stroke="none"
              className=""
              fill="none"
              d="M475.2 366.2l-346.6 99.9-25.3-100.7"
            />
            <path
              id="angleHCB"
              stroke="none"
              className=""
              fill="none"
              d="M129.3 365.5v99.7h-.9l-25.1-99.8"
            />
          </g>
          <g id="triangles">
            <path
              id="triangleABC"
              stroke="none"
              className=""
              fill="none"
              d="M128.6 466.1l346.6-99.9-371.9-.8 25.1 99.8h.9-.9l.2.7"
            />
            <path
              id="triangleDAF"
              stroke="none"
              className=""
              fill="none"
              d="M130.3 265.5l344.3 99.6-85.9-348-258.4 248.4"
            />
            <path
              id="triangleCDE"
              stroke="none"
              className=""
              fill="none"
              d="M129.3 266.4v198.8h-.9L78.3 266.4z"
            />
            <path
              id="triangleHCB"
              stroke="none"
              className=""
              fill="none"
              d="M129.3 365.5v99.7h-.9l-25.1-99.8z"
            />
          </g>

        </g>
      </g>
    </svg>
  );
}

export default SvgIcon;

