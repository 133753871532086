import React from 'react';
import Fraction from '../../../fraction2'
import Mag from '../../../magnitude'
import Note from '../../../note';

export default function Step2(){
  
  return <div className="step" id="Step2">
     <p>Within the circle of the Moon, draw a rectangle where one side is <Mag>line CD</Mag>, and then slice it diagonally to create <Mag>right triangle CDE</Mag>. The diagonal will go through the center of the circle, making it a diameter line, since Thales Theorem states that a right triangle in a circle will have its base on the circle's diameter. Then draw <Mag>Line AB</Mag> from your eye to the Moon's center.</p>

     <p><Mag>Triangle CDE</Mag> is proportional to <Mag>Triangle ABC</Mag>.</p>
    <Note note="Note on why Triangle CDE is proportional to Triangle ABC.">
      <p><Mag>Triangle CDE</Mag> is proportional to <Mag>Triangle ABC</Mag>, because it can be proven that all of their angles are equal.</p>
      
      <ol>
       <li><Mag>Angle EDC</Mag> = <Mag>Angle BCA</Mag>, since they are both right angles.<br/><br/></li>
      <li><Mag>Angle BAC</Mag> = <Mag color="red" >Angle HCB</Mag> Because when a right triangle's right angle is cut perpendicularly it makes two smaller proportional right triangles that are also proportional to itself. <br/>
      So since <Mag>Triangle ABC</Mag> is proportional to <Mag>Triangle HCB</Mag><br/>
      then <Mag>Angle BAC</Mag> = <Mag color="red" >Angle HCB</Mag>
      <br/><br/></li>
      <li>Therefore since <Mag>Triangle CDE</Mag> and <Mag>Triangle ABC</Mag>, have two angles in common, they must have all three angles in common.<br/><br/></li>
      </ol>
      <p>Which means <Mag>Triangle CDE</Mag> is proportional to <Mag>Triangle ABC</Mag></p>
    </Note>
      <p>Since the triangles are proportional, proportions can be established between the ratios of their sides.</p>

      <p>
        <Fraction>
          <Mag>Line AC</Mag>
          <Mag>Line AB</Mag>
        </Fraction>
        <span> = </span>
        <Fraction>
          <Mag>Line CD</Mag>
          <Mag>Line EC</Mag>
        </Fraction>
      </p>

      <p>Alternate the means</p>
      <p>
        <Fraction>
          <Mag>Line EC</Mag>
          <Mag>Line AB</Mag>
        </Fraction>
        <span> = </span>
        <Fraction>
          <Mag>Line CD</Mag>
          <Mag>Line AC</Mag>
        </Fraction>
      </p>
      <Note>
      <p>This is a significant proportion since 
        <br/><Mag>Line AB</Mag> is the distance of the Moon's center from your eye, 
        <br/><Mag>Line EC</Mag> is the Moon's diameter,
        <br/><br/> These are the two values we're interested in. But then what is the value of relating them to these two lines?: 
        <br/><br/><Mag>Line AC</Mag> is one of your sight-lines on the Moon, and 
        <br/><Mag>Line CD</Mag> is the line connecting your sight-lines.
        <br/><br/>That will become clear in the next step.</p>
      </Note>
  </div>
}