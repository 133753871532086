import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class Nav extends Component {

  render() {
    return <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    <NavLink className="navbar-brand" to={`/`} exact activeClassName='active'>  
      The Geometre's Grimoire
    </NavLink>

    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse" id="navbarsExampleDefault">
      <ul className="navbar-nav ml-auto">
        
        
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="http://example.com" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Interactive Geometry Proofs</a>
          <div className="dropdown-menu" aria-labelledby="dropdown01">
            <NavLink className="dropdown-item" to={`/euclids-optics`} activeClassName='active'>  
              Euclid's Optics: Proposition 8
            </NavLink>
            <NavLink className="dropdown-item" to={`/moon-proof-2`} activeClassName='active'>  
              Aristarchus' Moon Proof, part 2
            </NavLink>
          </div>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/corrections`} activeClassName='active'>  
            Corrections
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/contact`} activeClassName='active'>  
            Contact
          </NavLink>
        </li>
      </ul>
    </div>
  </nav>
    
  }
}