import React from 'react';
import Fraction from '../../../fraction2'
import Magnitude from '../../../magnitude';
import Note from '../../../note';

export default function Step5(){
  let text="Now, since the triangle ADB > sector GDB, and since the triangle BDC <  sector BDH, the triangle ADB, compared with the sector GDB, has a greater ratio than the triangle BDC, when compared with the sector BDH."

  return <div className="step" id="Step5">

  <p>5. But AC is equal to EF, and, as EF is to BC, so is FD to DC.</p>
  <Fraction>
    <Magnitude>line AC</Magnitude>
    <Magnitude>line BC</Magnitude>
  </Fraction> 
  <span> = </span>
  <Fraction>
    <Magnitude>line EF</Magnitude>
    <Magnitude>line BC</Magnitude>
  </Fraction> 
  <span> = </span>
  <Fraction>
    <Magnitude highlightLabel="Line FD">Distance FD</Magnitude>
    <Magnitude highlightLabel="Line DC">Distance DC</Magnitude>
  </Fraction>

  <p>so 
    <Fraction>
      <Magnitude highlightLabel="Line FD">Distance FD</Magnitude>
      <Magnitude highlightLabel="Line DC">Distance DC</Magnitude>  
    </Fraction>​​ can be substituted into the last expression from step 4.</p>
    <Fraction>
      <Magnitude highlightLabel="Line FD">Distance FD</Magnitude>
      <Magnitude highlightLabel="Line DC">Distance DC</Magnitude>
    </Fraction> 
  <span> &gt; </span>
  <Fraction>
    <Magnitude>Whole sector GDH</Magnitude>
    <Magnitude>Bottom sector BDH</Magnitude>
  </Fraction>
  <Note>
    <p>Substitute the distances for the base lines since they are proportional. This is because Triangle EFD is proportional to Triangle ACD so that</p>
    <p>
    <Fraction>
      <Magnitude>line EF</Magnitude>
      <Magnitude highlightLabel="line FD">Distance FD</Magnitude>
    </Fraction>
      <span> = </span>
    <Fraction>
      <Magnitude>line BC</Magnitude>
      <Magnitude highlightLabel="line DC">Distance DC</Magnitude>
    </Fraction>
    </p><p>So if you alternate the means, (which are literally the middle terms of EF:BC=FD:DC, so in this case, BC and FD) </p>
    <Fraction>
      <Magnitude>line EF</Magnitude>
      <Magnitude>line BC</Magnitude>
    </Fraction>
      <span> = </span>
      <Fraction>
        <Magnitude highlightLabel="line FD">Distance FD</Magnitude>
        <Magnitude highlightLabel="line DC">Distance DC</Magnitude>
    </Fraction>
    <br/><p>you end up with the same proportion in the proof.  
    </p>
  </Note>

  </div>
}