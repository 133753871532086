import react from 'react'

export default class Contact extends react.Component{

    render() { return <div className="container page">
        
        <div className="col-5">
          <h4>Have you found an error?<br/>
          Was a certain part confusingly written?
          </h4>
          <p>Please send an email to geometre@geometresgrimoire.com</p>
        </div>

    </div>
  }
}