import React from 'react';
import Fraction from '../../../fraction2'
import Note from '../../../note';
import Magnitude from '../../../magnitude';

export default function Step1(){
  let text="Now, since the triangle ADB > sector GDB, and since the triangle BDC <  sector BDH, the triangle ADB, compared with the sector GDB, has a greater ratio than the triangle BDC, when compared with the sector BDH."

  return <div className="step" id="Step1">
  <p>1. {text}</p>
  <Fraction>
    <Magnitude>greater triangle ADB</Magnitude>
    <Magnitude>smaller sector GDB</Magnitude>
  </Fraction>
  <span> &gt; </span>
  <Fraction>
    <Magnitude>smaller triangle BDC</Magnitude>
    <Magnitude>greater sector BDH</Magnitude>
  </Fraction>
    
  <Note>
    <p>Euclid compares the areas of the corresponding triangles and sectors. In terms of area it can be seen that the top triangle is bigger than the top sector, and that the bottom triangle is smaller than the bottom sector. So knowing what we know about ratios, a ratio with a top value that's bigger than its bottom value will be greater than another ratio whose top value is smaller than its bottom value.This is an inequality statement to describe non-proportional ratios. We'll use the modern day notation for expressing inequalities, where "&gt;" means greater than and "&lt;" means less than.</p>
  </Note>
  </div>
}