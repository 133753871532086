import React from 'react';

export default class Fraction extends React.Component {
  
  state={
    freezeHighlight:0,
    hover:0
  }

  render() {
  return <div className={ `frac 
  ${ this.state.freezeHighlight? "clickHighlight" : '' }
  ${ this.state.hover? "highlight" : '' }`}  onMouseOver={()=>{ 
        const event = new CustomEvent("highlightRatio", {detail:this.props});
        // Listen for the event.
        window.dispatchEvent(event);
        this.setState((curState)=>{
          return {
            ...curState,
            hover: 1
          }
        })
      }}

      onClick={()=>{
        this.setState((curState)=>{
          return {
            ...curState,
            freezeHighlight: (!curState.freezeHighlight)
          }
        })
      }}

      onMouseOut={()=>{ 
        const event = new CustomEvent("unhighlightRatio", {detail:this.props});
        // Listen for the event.
        if(!this.state.freezeHighlight) {
          window.dispatchEvent(event);
        }
        this.setState((curState)=>{
          return {
            ...curState,
            hover: 0
          }
        })
      }}

      >
      <span className="top">{ this.props.top }</span>
      <span className="symbol">/</span>
      <span className="bottom">{ this.props.bottom }</span>
    </div>  
  }
}