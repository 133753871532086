import React from 'react';

export default class Magitude extends React.Component {
  
  state={
    freezeHighlight:0,
    hover:0
  }

  triggerHighlight = (e) => {
    let targetClassName = this.props.className || "top";
    if(targetClassName) {
      const event = new CustomEvent("highlightRatio", {detail:{
        [targetClassName]: (this.props.highlightLabel || this.props.children),
        props:this.props
      }});
      // Listen for the event.
      window.dispatchEvent(event);
    }
  }
  
  triggerUnhighlight = (e) => {

    let targetClassName = this.props.className || "top";
    if(targetClassName) {
      const event = new CustomEvent("unhighlightRatio", {detail:{
        [targetClassName]: (this.props.highlightLabel || this.props.children),
        props:this.props
      }});
      // Listen for the event.
      if(!this.state.freezeHighlight) {
        window.dispatchEvent(event);
      }
    }
  }

  render() {
  if(this.props.className) {
    if (this.props.highlightRatio) {
      this.triggerHighlight();
    } else {
      this.triggerUnhighlight();
    }
  }
  return <span className={ `mag 
  ${ (this.state.freezeHighlight ||
    this.props.freezeHighlight ||
    this.props.highlightRatio)? "clickHighlight" : '' }
  ${ this.state.hover? "highlight" : '' }
  ${this.props.className || ""}
  `}  
   /* Remove for now   
      onClick={()=>{
        this.setState((curState)=>{
          return {
            ...curState,
            freezeHighlight: (!curState.freezeHighlight)
          }
        })
      }}
*/
      onMouseOver={()=>{
        this.setState((curState)=>{
        return {
          ...curState,
          hover: 1
          }
        })
        this.triggerHighlight()
      }}


      onMouseOut={()=>{ 
        this.setState((curState)=>{
          return {
            ...curState,
            hover: 0
          }
        })
        this.triggerUnhighlight()
      }}

      >
      { this.props.children }
    </span>
  }
}