import React from 'react';
import Fraction from '../../../fraction2'
import Magnitude from '../../../magnitude'
import Note from '../../../note';

export default function Step6(){
  let text="And as one sector is to the other sector, so the angle GDH compared with the angle BDH."

  return <div className="step" id="Step6">
  <p>6. {text}</p>
  <Fraction>
    <Magnitude highlightLabel="line FD">Distance FD</Magnitude>
    <Magnitude highlightLabel="line DC">Distance DC</Magnitude> 
  </Fraction>
  <span> &gt; </span>
  <Fraction>
    <Magnitude>Whole angle GDH</Magnitude>
    <Magnitude>Bottom angle BDH</Magnitude> 
  </Fraction>
  
  <p>And from the angle GDH, AC is seen, and  from the angle BDH, EF is seen. So lines of equal length are not seen in proportion to the distances.</p>
  <p>End of proof.</p>
  <Note>
    <p>And with that substitution, the distances have been related to the sectors, which are analagous to the angles
      that the posts are seen through. And so the proposition has been proven. The ratio of the distance of the object farther away to the distance of the closer object is greater than the ratio of the angle of the sight lines to the closer object to the angle of the sight lines to the farther object.</p>

    <p>Therefore, when it comes to sight, there is no proportionality between the distance an object is away from the eye and the size of the object. The proof is interesting because it doesn't relate to the proposition statement directly, yet the conclusions drawn from it satisfy the real world demand of the proposition statement.</p>
      
      <p>You may wonder why is the greater distance being compared against the greater angle when in reality it corresponds with the lesser angle? Would that result in a proportional relationship? We could try that comparison and the same inequality would still exist.</p>


<p>Starting with this inequality</p>
<p>  
  <Fraction>
    <>Greater Distance</>
    <>Lesser Distance</>
  </Fraction>
  <span> &gt; </span>
  <Fraction>
    <>Greater Angle</>
    <>Lesser Angle</>
  </Fraction>
</p>
<p>If we were to invert the lesser angle to the top of the ratio, the ratio will be even lesser than the original ratio </p>
  <p>
    <Fraction>
      <>Greater Distance</>
      <>Lesser Distance</>
    </Fraction> 
    <span> &gt; </span>
    <Fraction>
      <>Greater Angle</>
      <>Lesser Angle</>
    </Fraction>
    <span> &gt; </span>
    <Fraction>
      <>Lesser Angle</>
      <>Greater Angle</>
    </Fraction>
  </p>
  <p>Since that's like if we started with
    <Fraction>
      <>4</>
      <>1</>
    </Fraction>​​ and inverted it to 
    <Fraction>
      <>1</> 
      <>4</>
    </Fraction> . And so in a round-about way it has been shown that there's no proportional relationship between the distance an object is from us and the angle it's seen between. But that doesn't mean all is lost. We atleast know that one is greater than the other, and that does count for something, as Aristarchus will soon show us.
  </p>
  </Note>
  </div>

}