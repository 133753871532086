import React from 'react';
import Fraction from '../../../fraction2'
import Magnitude from "../../../magnitude";
import Note from '../../../note';

export default function Step4(){
  let text="But as the triangle ADC is to the triangle BDC, so is AC to BC. \
  so AC to BC can be substituted in from the last expression from step 3."

  return <div className="step" id="Step4">
  <p>4. {text}</p>

  <Fraction>
    <Magnitude>line AC</Magnitude>
    <Magnitude>line BC</Magnitude>
  </Fraction>
  <span> &gt; </span>
  <Fraction>
    <Magnitude>Whole sector GDH</Magnitude>
    <Magnitude>Bottom sector BDH</Magnitude>
  </Fraction>
  <Note>
    <p>Substitute the base lines for the triangles since they are proportional. I actually think this is the most significant part of this proof since it relates the posts to the angles they are seen through. From a technical stand-point, disregarding its analogy to vision and only thinking in terms of shapes, I think it's signifcant that it combines a proof about triangles and a proof about sectors to make a strange new proof. For me, the application to the real world distances is secondary. I think that explains why the proposition statement seems so loosely associated with the proof. It is always possible that this part of the proof originally  existed separately in it's own proof, but was lost.</p>
        
  </Note>
  </div>
}